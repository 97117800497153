<template>
  <div id="batchResultDialog">
    <el-dialog title="批量出场结果" :visible.sync="show" @closed="closed">
      <h6>出场成功车辆</h6>
      <div class="table-container">
        <el-table :data="successList" style="width: 100%" v-loading="loading">
          <el-table-column prop="plateNum" label="车牌号" align="center">
          </el-table-column>
          <el-table-column prop="vehicleType" label="车辆类型" align="center">
          </el-table-column>
          <el-table-column prop="arrivalTime" label="入场时间" align="center">
          </el-table-column>
          <el-table-column prop="channelName" label="入场设备" align="center">
          </el-table-column>
          <el-table-column prop="parkingTime" label="停车时长" align="center">
          </el-table-column>
        </el-table>
      </div>
      <h6>出场失败车辆</h6>
      <div class="table-container">
        <el-table :data="errorList" style="width: 100%" v-loading="loading">
          <el-table-column prop="plateNum" label="车牌号" align="center">
          </el-table-column>
          <el-table-column prop="vehicleType" label="车辆类型" align="center">
          </el-table-column>
          <el-table-column prop="arrivalTime" label="入场时间" align="center">
          </el-table-column>
          <el-table-column prop="channelName" label="入场设备" align="center">
          </el-table-column>
          <el-table-column prop="parkingTime" label="停车时长" align="center">
          </el-table-column>
        </el-table></div
    ></el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      loading: false,
      successList: [],
      errorList: [],
    };
  },
  methods: {
    closed() {
      this.successList = [];
      this.errorList = [];
    },
  },
};
</script>
<style lang="scss">
#batchResultDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 800px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .table-container {
          .el-table
            .el-table__body-wrapper
            .el-table__body
            td.el-table__cell
            .cell {
            color: black;
          }
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
